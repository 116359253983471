"use strict";

jQuery(document).ready(function ($) {
  $.init = {
    init: function init() {
      $(document).trigger('pginit');
    }
  };
  $.init.init();
});
"use strict";

(function ($) {
  $.accordion = {
    init: function init(base, element, targets) {
      $(element, base).each(function () {
        $.accordion.events($(this), base, targets);
      });
    },
    events: function events(element, base, targets) {
      element.eventregister({
        events: ["click"],
        onClick: function onClick(trigger) {
          var triggerbase = trigger.closest(base.selector);
          var target = $(targets.join(','), trigger);

          if (target.hasClass($.main.is_open_class)) {
            target.removeClass($.main.is_open_class);
            target.addClass($.main.is_close_class);
          } else {
            target.addClass($.main.is_open_class);
            target.removeClass($.main.is_close_class);
          }

          if (trigger.hasClass($.main.is_active_class)) {
            trigger.removeClass($.main.is_active_class);
          } else {
            trigger.addClass($.main.is_active_class);
          }

          if (triggerbase.hasClass($.main.is_active_class)) {
            triggerbase.removeClass($.main.is_active_class);
          } else {
            triggerbase.addClass($.main.is_active_class);
          } //$.desktop.parallax_refresh();


          return false;
        }
      });
    }
  };
})(jQuery);
"use strict";

jQuery(document).ready(function ($) {
  $.pgajax = {
    addloader: function addloader(base, top) {
      var container = $(document.createElement('div'));
      container.addClass('m-ajax-background');
      container.css('top', top + 'px');
      base.append(container);
    },
    remove_loader: function remove_loader(base) {
      $('.m-ajax-background', base).remove();
    }
  };
  $(document).on({
    ajaxcall: function ajaxcall(obj, options) {
      var data = options.data;
      var url = data.url;
      $.ajax({
        url: url,
        options: options,
        data: data.data,
        type: 'POST',
        success: function success(data_from_call) {
          options.sucess(data_from_call, this.options);
        }
      });
    }
  });
});
"use strict";

(function ($) {
  $.breakpoints = {
    current: '',
    breakpoints: [],
    match: function match() {
      try {
        //reset
        $.breakpoints.breakpoints = [];
        var breakpoints = Drupal.settings.clew.breakpoints;

        for (breakpoint in breakpoints) {
          var mql = window.matchMedia(breakpoints[breakpoint]);

          if (mql.matches === true) {
            $.breakpoints.breakpoints.push(breakpoint);
          }
        }

        return $.breakpoints.breakpoints;
      } catch (e) {//alert(e);
      }
    },
    get: function get() {//console.log($.breakpoints.breakpoints);
    },
    change: function change() {
      var breakpoints = $.breakpoints.match();
      var breakpoint = breakpoints.slice(-1).pop();

      if ($.breakpoints.current !== breakpoint) {
        //fire event
        $(document).trigger('breakpointchanged', {});
      }

      $.breakpoints.current = breakpoint;
    }
  };
  $(document).bind({
    resized: function resized(e, status) {//$.breakpoints.change()
    }
  });
})(jQuery);
"use strict";

(function ($) {
  $.browser = {
    is_mobile: false,
    is_tablet: false,
    is_touch: false,
    ipad_ls: false,
    use_mediamode: false,
    isMobileAgent: null,
    bpconfig: [{
      label: 'bp-mobile',
      config: {}
    }, {
      label: 'bp-tablet',
      config: {}
    }],
    init: function init() {
      $.browser.ie();
      $.browser.setMediaMode();
      $.browser.setMobileAgent();
      $.browser.setClasses();
    },
    isTouch: function isTouch() {
      return !!("ontouchstart" in window) || !!navigator.msMaxTouchPoints;
    },
    ie: function ie() {
      if ($.browser.getIEVersion() > 0) {
        $('body').addClass('ie'); // Add the version number

        $('body').addClass('ie' + $.browser.getIEVersion());
      }
    },
    getIEVersion: function getIEVersion() {
      var sAgent = window.navigator.userAgent;
      var Idx = sAgent.indexOf("MSIE"); // If IE, return version number.

      if (Idx > 0) {
        return parseInt(sAgent.substring(Idx + 5, sAgent.indexOf(".", Idx)), 10);
      } // If IE 11 then look for Updated user agent string.
      else if (!!navigator.userAgent.match(/Trident\/7\./)) return 11;else return 0; //It is not IE

    },
    setMediaMode: function setMediaMode() {
      try {
        if ($.browser.use_mediamode === true) {
          mediamode = window.getComputedStyle(document.body, ':after').getPropertyValue('content');

          if (mediamode == '"mobile"' || mediamode == 'mobile' || mediamode == "'mobile'") {
            $.browser.is_mobile = true;
          }

          if (mediamode == '"tablet"' || mediamode == 'tablet' || mediamode == "'tablet'") {
            $.browser.is_mobile = true;
            $.browser.is_tablet = true;
          }

          if (mediamode == '"desktop"' || mediamode == 'desktop' || mediamode == "'desktop'") {
            $.browser.is_mobile = false;
          }

          if (mediamode == '"none"' || mediamode == 'none' || mediamode == "'none'") {
            $.browser.is_mobile = false;
          }

          if (mediamode == '"ipadls"' || mediamode == 'ipadls' || mediamode == "'ipadls'") {
            $.browser.is_mobile = false;
            $.browser.ipad_ls = true;
          }
        }
      } catch (e) {}
    },
    setClasses: function setClasses() {
      if ($.browser.is_mobile === true) {
        $('body').addClass('mobile');
      }

      if ($.browser.is_tablet === true) {
        $('body').addClass('tablet');
      }
    },
    setMobileAgent: function setMobileAgent() {
      var md = new MobileDetect(window.navigator.userAgent);

      if (md.tablet() !== null) {
        $.browser.is_tablet = true;
      } //ext events


      $.browser.isMobileAgent = {
        Android: function Android() {
          return navigator.userAgent.match(/Android/i);
        },
        BlackBerry: function BlackBerry() {
          return navigator.userAgent.match(/BlackBerry|BB10/i);
        },
        iOS: function iOS() {
          return navigator.userAgent.match(/iPhone|iPad|iPod/i);
        },
        Opera: function Opera() {
          return navigator.userAgent.match(/Opera Mini/i);
        },
        Windows: function Windows() {
          return navigator.userAgent.match(/IEMobile/i);
        },
        any: function any() {
          return $.browser.isMobileAgent.Android() || $.browser.isMobileAgent.BlackBerry() || $.browser.isMobileAgent.iOS() || $.browser.isMobileAgent.Opera() || $.browser.isMobileAgent.Windows();
        }
      };

      if ($.browser.isMobileAgent.any()) {
        $.browser.is_mobile = true;
      }
    }
  };
  $.browser.init();
  $(document).bind({
    resized: function resized(e, status) {
      //simulate mobile if breakpoint match (on resizer only)
      var breakpoints = $.breakpoints.match();

      if (!breakpoints) {
        return;
      }

      var breakpoint = breakpoints.slice(-1).pop();

      if (breakpoint === 'bp-mobile') {
        $.browser.is_mobile = true;
      } else {
        $.browser.is_mobile = false;
      }

      if (breakpoint === 'bp-tablet' || breakpoint === 'bp-tablet-only') {
        $.browser.is_tablet = true;
      } else {
        $.browser.is_tablet = false;
      }

      if ($.browser.isTouch() === true) {
        $('body').addClass('is_touch');
        $.browser.is_touch = true;
      } else {
        $('body').removeClass('is_touch');
        $.browser.is_touch = false;
      }
    }
  });
})(jQuery);
"use strict";

(function ($, undefined) {
  'use strict';

  var defaults = {
    events: [],
    return_false: false,
    callback_before: '',
    callback_after: '',
    selector: null,
    onClick: function onClick(element, settings) {
      if (settings.callback_before) {
        settings.callback_before.call(this, element, settings);
      }

      if (element.hasClass($.main.is_active_class)) {
        element.removeClass($.main.is_active_class);
      } else {
        element.addClass($.main.is_active_class);
      }

      if (settings.callback_after) {
        settings.callback_after.call(this, element, settings);
      }
    }
  };

  $.fn.eventregister = function (options, selector) {
    var element = this;

    if (element.selector) {
      selector = element.selector;
    }

    var settings = $.extend(true, {}, defaults, options);
    settings.selector = selector;

    if (jQuery.inArray('click', settings.events) !== -1) {
      if (this.length === 0) {
        return this;
      }

      if (this.length > 1) {
        this.each(function () {
          $(this).eventregister(options, selector);
        });
        return this;
      }

      var event = 'click';

      if ($.browser.is_mobile === true) {
        event = 'click';
      }

      element.data('settings', settings);
      element.off(event).on(event, function (e) {
        e.stopPropagation();
        e.preventDefault();
        settings.onClick.call(this, element, $(this).data().settings);
        return false;
      });
    }
  };
})(jQuery);
"use strict";

(function ($) {
  $.helper = {
    handleScrollbar: function handleScrollbar(option) {
      var scrollDiv = document.createElement('div');
      scrollDiv.className = 'scrollbar-measure';
      document.body.appendChild(scrollDiv); // Get the scrollbar width

      var scrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth;
      var hasScrollbar = window.innerWidth > document.documentElement.clientWidth;

      if (option === 'open' && $('.scrollbar-measure').length === 1) {
        if (hasScrollbar) {
          //$('.m-pg-menu__overlay').css('margin-left', '');
          $('html').css('margin-right', scrollbarWidth); ///$('body').css('margin-right', (-1 * scrollbarWidth));
        }
      } else if (option === 'close') {
        $('.m-pg-menu__overlay').css('margin-left', scrollbarWidth / 2);
        $('html').css('margin-right', ''); //$('body').css('margin-right', '');
      } // Delete the DIV


      document.body.removeChild(scrollDiv);
    },
    matchconfig: function matchconfig(configarray, match) {
      var cdefault = {};

      for (var i in configarray) {
        //set fallback
        if (configarray[i].label === 'default') {
          cdefault = configarray[i].config;
        } //if match with breakpoint


        if (configarray[i].label === match) {
          return configarray[i].config;
        }
      } //fallback


      return cdefault;
    },
    addlink: function addlink(base, html, classname, callback) {
      var a = $(document.createElement('a'));
      a.addClass(classname);
      a.html(html);
      a.attr('href', '#');
      base.append(a); //with click event

      $(base).on('click', '.' + classname, function (e) {
        e.preventDefault();
        e.stopPropagation();

        if (typeof callback === 'function') {
          callback();
        }

        return false;
      });
    },
    scrollto: function scrollto(obj_to_srcolltop, extra_offset) {
      var scrollDestination = obj_to_srcolltop.offset().top;

      if (extra_offset) {
        scrollDestination += extra_offset;
      }

      $('html, body').animate({
        scrollTop: scrollDestination
      }, 1000, 'easeInSine', function () {});
    },
    decodeHTMLEntities: function decodeHTMLEntities(str) {
      var element = document.createElement('div');

      if (str && typeof str === 'string') {
        str = str.replace(/<script[^>]*>([\S\s]*?)<\/script>/gmi, '');
        str = str.replace(/<\/?\w(?:[^"'>]|"[^"]*"|'[^']*')*>/gmi, '');
        element.innerHTML = str;
        str = element.textContent;
        element.textContent = '';
      }

      return str;
    },
    get_event: function get_event() {
      var clickevent = 'click';

      if ($.browser.is_mobile === true) {
        clickevent = 'touchstart';
      }

      if ($.browser.is_tablet === true) {
        clickevent = 'touchstart';
      }
      /*if (($.browser.is_mobile === true) || ($.browser.is_tablet === true)) {
       clickevent = 'touchstart';
       }*/


      return clickevent;
    }
  };
})(jQuery);
"use strict";

(function ($) {
  $.main = {
    is_active_class: 'is-active',
    is_open_class: 'is-opened',
    is_close_class: 'is-closed',
    is_hover_class: 'is-hover',
    is_focus_class: 'is-focus',
    is_ajax_class: 'is-ajax',
    in_trail_class: 'in-trail',
    has_value_class: 'has-value',
    revalidate_class: 'revalidate',
    shrink_class: 'shrink',
    hover_is_open: false
  };
})(jQuery);
"use strict";

(function ($) {
  $.resizer = {
    resizeTimer: null,
    resizeTimerNoChache: null,
    init: function init() {
      $.resizer.events();
      $.resizer.resize(true);
    },
    events: function events() {
      $(window).resize(function () {
        clearTimeout($.resizer.resizeTimer);
        $.resizer.resizeTimer = setTimeout($.resizer.resize, 200);
        clearTimeout($.resizer.resizeTimerNoChache);
        $.resizer.resizeTimerNoChache = setTimeout($.resizer.resizeNoCache, 30);
      });
    },
    resize: function resize(init) {
      //fire resizeevents
      $(document).trigger('resized', {});
    },
    resizeNoCache: function resizeNoCache(init) {
      //fire resizeevents without a timer (be carefull !!!!)
      $(document).trigger('resized_nocache', {});
    }
  };
  $.resizer.init();
})(jQuery);
"use strict";

(function ($) {
  $.ask_clew = {
    init: function init() {
      var cookie_name = 'cookie_ask_clew';
      var $ask_clew_overlay__wrapper = $('.ask-clew-overlay__wrapper');

      if ($ask_clew_overlay__wrapper.size() > 0) {
        var cookie = $.cookie(cookie_name);

        if (!cookie) {
          $ask_clew_overlay__wrapper.show();
          $('.ask-clew-overlay__close', $ask_clew_overlay__wrapper).on('click', function (e) {
            $ask_clew_overlay__wrapper.animate({
              bottom: '-600px'
            }, 'slow', $ask_clew_overlay__wrapper.hide);
            $.cookie(cookie_name, '1', {
              expires: 2
            });
            return false;
          });
        }
      }
    }
  };
  $(document).bind({
    pginit: function pginit(e, status) {
      $.ask_clew.init();
    }
  });
})(jQuery);
"use strict";

(function ($) {
  // webform submit
  Drupal.behaviors.webform_ajax = {
    attach: function attach(context, settings) {
      jQuery('.webform-client-form', context).once('webform_ajax', function () {
        if ($('.node-type-page .webform-client-form select').size() > 0) {
          $('.node-type-page .webform-client-form select').select2();
        }
      });
    }
  };
})(jQuery);

(function ($) {
  $.eventForms = {
    init: function init() {
      // show and hide webform
      $(document).on('click', 'a.m-node--event__apply', function (e) {
        e.preventDefault();
        var $form = $('.m-form--application__event');

        if ($form.is('.active')) {
          $form.slideUp(500, function () {
            $(this).removeClass('active').removeAttr('style');
          });
        } else {
          $form.addClass('active').hide().stop().slideDown(500);
        }
      }); // select2

      $('.node-type-event .webform-client-form select, .node-type-webform .webform-client-form select, .node-type-page .webform-client-form select').each(function () {
        var options = {
          allowClear: false,
          minimumResultsForSearch: -1
        };

        if ($(this).attr('name') === 'submitted[topics][topics_i_cover][]') {
          options.maximumSelectionSize = 6;
        }

        $(this).select2(options);
      }); // show and hide members 3+4 in application webform

      $(document).on('click', '#webform-component-team-member-3 legend, #webform-component-team-member-4 legend', function (e) {
        e.preventDefault();
        var $form = $(this).parent();

        if ($form.is('.active')) {
          $form.find('.fieldset-wrapper').slideUp(300, function () {
            $form.removeClass('active');
          });
        } else {
          $form.find('.fieldset-wrapper').slideDown(300, function () {
            $form.addClass('active');
          });
        }
      });

      if ($('.m-node--webform__messages .error').size() > 0) {
        if ($('#webform-component-team-member-3--personal-details--first-name input').val() != '' || $('#webform-component-team-member-3--personal-details--surname input').val() != '' || $('#webform-component-team-member-3--personal-details--email input').val() != '') {
          $('#webform-component-team-member-3').addClass('active');
        }

        if ($('#webform-component-team-member-4--personal-details--first-name input').val() != '' || $('#webform-component-team-member-4--personal-details--surname input').val() != '' || $('#webform-component-team-member-4--personal-details--email input').val() != '') {
          $('#webform-component-team-member-4').addClass('active');
        }
      } // show event form on validation error


      if ($('.node-type-event .m-node--webform__messages.error').size() > 0) {
        $('.m-form--application__event').addClass('active');
      } // hacky webform translation


      $('.node-type-event .webform-client-form label').each(function () {
        var local_label;
        var $this = $(this).clone();
        $('span', $this).remove();
        var label = $this.text().split('|');
        var span = $('span', this);
        var spanelement = '';

        if (span.length > 0) {
          spanelement = span[0].outerHTML;
        }

        if ($('body').hasClass('i18n-de')) {
          local_label = label[1];
        } else {
          local_label = label[0];
        }

        var mylabel = '';

        if (span.html()) {
          mylabel = span.html();
        }

        $(this).text(local_label + mylabel);
      }); // hacky webform translation

      $('.node-type-event .webform-client-form .webform-component-markup, .node-type-event .webform-client-form .webform-component .description').each(function () {
        var local_markup;
        var markup = $(this).html().split('|');

        if ($('body').hasClass('i18n-de')) {
          local_markup = markup[1];
        } else {
          local_markup = markup[0];
        }

        $(this).html(local_markup);
      }); // hacky webform translation

      $('.node-type-event .messages.error li').each(function () {
        var local_label;
        var label = $(this).text().replace(' field is required.', '').split('|');

        if ($('body').hasClass('i18n-de')) {
          local_label = label[1];
        } else {
          local_label = label[0];
        } // console.log(local_label)


        $("body.i18n-en ".concat(this)).text(local_label + " field is required.");
        $("body.i18n-de ".concat(this)).text(local_label + " ist erforderlich.");
      });
    }
  };
  $(document).bind({
    pginit: function pginit(e, status) {
      $.eventForms.init();
    }
  });
})(jQuery);
"use strict";

(function ($) {
  $.expertfacts = {
    init: function init() {
      $('.m-expertfact__title').click(function (e) {
        var obj = $(this).closest('.m-expertfacts__trigger');

        if (obj.hasClass($.main.is_active_class)) {
          obj.removeClass($.main.is_active_class);
        } else {
          $('.m-expertfacts__trigger').removeClass($.main.is_active_class);
          obj.addClass($.main.is_active_class);
        } //alert($('.m-expertfacts__trigger.' + $.main.is_active_class).length);


        if ($('.m-expertfacts__trigger.' + $.main.is_active_class).length > 0) {
          $('.m-expertfacts').addClass($.main.is_open_class);
        } else {
          $('.m-expertfacts').removeClass($.main.is_open_class);
        }

        return false;
      });
    }
  };
  $(document).bind({
    pginit: function pginit(e, status) {
      $.expertfacts.init();
    }
  });
})(jQuery);
"use strict";

(function ($) {
  $.experts_pdfgen_button = {
    init: function init() {
      var $experts_filter_form = $('#views-exposed-form-lists-experts');

      if ($experts_filter_form.length > 0) {
        $experts_filter_form.find('.views-exposed-widgets').append('<div class="views-exposed-widget"><label> </label><a target="_blank" href="/pdfgen_experts' + window.location.search + '" class="m-node--list--experts__turnbutton">Turn Selection into PDF</a></div>');
      }
    }
  };
  $(document).bind({
    pginit: function pginit(e, status) {
      $.experts_pdfgen_button.init();
    }
  });
})(jQuery);
"use strict";

(function ($) {
  $.faq = {
    init: function init() {
      var class_array = [".m-paragraph--faq__questionanswer__trigger", ".m-paragraph--faqquestionanwser__question", ".m-paragraph--faqquestionanwser__answer", ".m-paragraph--faq__questionanswer"];
      $.accordion.init($(".m-paragraph--faq__questionanswer__trigger[data-open=\"0\"] .m-paragraph--faq__questionanswer"), ".m-paragraph--faqquestionanwser__question", class_array);
      $(".m-paragraph--faq__questionanswer__trigger[data-open=\"1\"] .m-paragraph--faq__questionanswer").click(function (e) {
        if (!$(e.target).is('a')) {
          var base = $(this).closest('.m-paragraph--faq__questionanswer__trigger');

          if (base.hasClass($.main.is_open_class)) {
            base.removeClass($.main.is_open_class);
          } else {
            base.addClass($.main.is_open_class);
          }

          return false;
        }
      });
    }
  };
  $(document).bind({
    pginit: function pginit() {
      $.faq.init();
    }
  });
})(jQuery);
"use strict";

(function ($) {
  $.footer = {
    init: function init() {
      $('.m-footer__back-to-top a.back-to-top').on('click', this.backToTop); // email

      var emailLink = $('.js-contact-email');

      if (emailLink.length > 0) {
        var emailHex = '&#x69;&#x6e;&#x66;&#x6f;&#x40;&#x63;&#x6c;&#x65;&#x61;&#x6e;&#x65;&#x6e;&#x65;&#x72;&#x67;&#x79;&#x77;&#x69;&#x72;&#x65;&#x2e;&#x6f;&#x72;&#x67;';
        emailLink.html(emailHex);
        emailLink.on('click', function (e) {
          e.preventDefault();
          var emailEncrypt = $.footer.emailDecode('vasb@pyrnararetljver.bet'); // ROT13 of info@...

          window.open('mailto:' + emailEncrypt, '_self');
          return false;
        });
      }
    },
    backToTop: function backToTop(e) {
      e.preventDefault();
      $('html, body').stop().animate({
        scrollTop: 0
      }, 500, 'swing');
    },
    emailDecode: function emailDecode(str) {
      // ROT13 decode
      var mapArray = [];
      var elements = "abcdefghijklmnopqrstuvwxyz";
      var output = "";

      for (var i = 0; i < elements.length; i++) {
        var x = elements.charAt(i);
        var y = elements.charAt((i + 13) % 26);
        mapArray[x] = y;
        mapArray[x.toUpperCase()] = y.toUpperCase();
      }

      for (var _i = 0; _i < str.length; _i++) {
        var c = str.charAt(_i);
        output += c >= 'A' && c <= 'Z' || c >= 'a' && c <= 'z' ? mapArray[c] : c;
      }

      return output;
    },
    toHex: function toHex(str) {
      var result = '';

      for (var i = 0; i < str.length; i++) {
        result += str.charCodeAt(i).toString(16);
      }

      return result;
    }
  };
  $(document).bind({
    pginit: function pginit(e, status) {
      $.footer.init();
    }
  });
})(jQuery);
"use strict";

(function ($) {
  $.header = {
    init: function init() {
      if ($.browser.is_mobile === true) {
        return;
      }

      var self = this;
      var threshold = 90;
      var $shrinkable = $('.l-header, .m-mainmenu__inner, .region-lowerheader .m-subtopic__outer');
      $(document, 'header#header + div#page').scroll(function () {
        var scroll = self.getCurrentScroll();

        if (scroll >= threshold && !$('.l-header').hasClass('is-active')) {
          $shrinkable.addClass($.main.shrink_class);
        } else {
          $shrinkable.removeClass($.main.shrink_class);
        }
      }); // Trigger firefox header resize on page load for glossar offset issue

      $(window).scrollTop($(window).scrollTop() + 1);
    },
    getCurrentScroll: function getCurrentScroll() {
      return window.pageYOffset || document.documentElement.scrollTop;
    }
  };
  $(document).bind({
    pginit: function pginit(e, status) {
      $.header.init();
    }
  });
})(jQuery);
"use strict";

window.headerimage = {
  headerimage_selector: '.m-node--frontpage__headerimage',
  init: function init() {
    var headerimages = document.querySelectorAll(window.headerimage.headerimage_selector);

    if (headerimages) {
      var headerimage = headerimages[Math.floor(Math.random() * headerimages.length)];

      if (headerimage) {
        headerimage.classList.add('is-active');
      }
    }
  }
};
document.addEventListener('DOMContentLoaded', function () {
  window.headerimage.init();
});
"use strict";

(function ($) {
  $.image_title_inject = {
    init: function init() {
      var $contents = $('.field-name-field-global-text, .field-name-body');
      $contents.find('img').each(function () {
        var $img = $(this);
        var title = $img.prop('title');
        var $parent = $img.parent();
        var $after_point = $img;
        var $has_caption = false; // parent

        if ($parent.prop('tagName') === 'A') {
          $after_point = $parent;
        } // caption


        if (title) {
          $has_caption = true;
          $after_point.after('<div class="m-global__image_title_inject">' + title + '</div>');
        } // image size + format


        var newImg = new Image();
        newImg.src = $img.attr('src');

        newImg.onload = function () {
          var height = newImg.height;
          var width = newImg.width;

          if (height > width) {
            $img.addClass('image-portrait');

            if ($has_caption === true) {
              $after_point.next('.m-global__image_title_inject').andSelf().wrapAll('<span class="m-global__image--portrait"></span>');
            } else {
              $after_point.wrap('<span class="m-global__image--portrait"></span>');
            }
          }
        };
      });
    }
  };
  $(document).bind({
    pginit: function pginit() {
      $.image_title_inject.init();
    }
  });
})(jQuery);
"use strict";

(function ($) {
  $.infobox = {
    init: function init() {
      $.accordion.init($('.m-paragraph--infobox'), '.m-paragraph--infobox__title', ['.m-paragraph--infobox__title', '.m-paragraph--infobox']);
    }
  };
  $(document).bind({
    pginit: function pginit(e, status) {
      $.infobox.init();
    }
  });
})(jQuery);
"use strict";

(function ($) {
  $.menu = {
    init: function init() {
      $('.m-mainmenu__container .menu_icon').click(this.toggleMenu);

      if ($.browser.is_mobile) {
        $.menu.activeSubmenu();
        $('.m-menuitem__title').click(this.toggleSubmenu);
      }
    },
    toggleMenu: function toggleMenu(e) {
      e.preventDefault();
      var $navElements = $('.m-mainmenu__inner, .m-mainmenu__inner > .innerWrapper > ul.menu > li');
      var $responsiveHeaderBlocks = $('.m-block--searchform, .m-block--sociallinks, .m-servicemenu__container');
      $navElements.css('opacity', 0);

      if ($.browser.is_mobile) {
        $responsiveHeaderBlocks.css('opacity', 0);
      }

      $('.menu_icon--hamburger').toggleClass('active');
      $('.m-mainmenu__container, .l-header, #page').toggleClass($.main.is_active_class);
      $(window).scrollTop(0);
      anime({
        targets: '.m-mainmenu__inner',
        opacity: 1,
        easing: 'easeInOutQuad',
        duration: 150,
        complete: function complete() {
          $navElements.css('opacity', 1);
          $responsiveHeaderBlocks.css('opacity', 1);

          if (!$.browser.is_mobile) {
            $.menu.animateMenuElements();
          }
        }
      });
    },
    animateMenuElements: function animateMenuElements() {
      anime({
        targets: '.m-mainmenu__inner > .innerWrapper > ul.menu > li',
        opacity: [0, 1],
        translateX: ['-50px', 0],
        easing: 'easeInOutQuad',
        duration: 300,
        delay: function delay(el, i, l) {
          return i * 50;
        }
      });
      anime({
        targets: '.m-topics__list li',
        opacity: [0, 1],
        scale: [.8, 1.1, 1],
        easing: 'easeInOutQuad',
        elasticity: 200,
        duration: 500,
        delay: function delay(el, i, l) {
          return i * 50;
        }
      });
    },
    activeSubmenu: function activeSubmenu(e) {
      var $activeLink = $('.m-mainmenu__inner > .innerWrapper > ul.menu > li > .m-menuitem > .m-menuitem__title > a.active-trail');
      var obj = $activeLink.closest('.m-menuitem');
      $('.m-menuitem').removeClass($.main.is_active_class);
      obj.addClass($.main.is_active_class);
    },
    toggleSubmenu: function toggleSubmenu(e) {
      e.preventDefault();
      var obj = $(this).closest('.m-menuitem');

      if (obj.hasClass($.main.is_active_class)) {
        obj.removeClass($.main.is_active_class);
      } else {
        $('.m-menuitem').removeClass($.main.is_active_class);
        obj.addClass($.main.is_active_class);
      }
    }
  };
  $(document).bind({
    pginit: function pginit(e, status) {
      $.menu.init();
    }
  });
})(jQuery);
"use strict";

(function ($) {
  $.mtoc = {
    init: function init() {
      $(document).on('click', '.m-toc__selector', this.openToc);
      $(document).on('click', '.m-toc__trigger a', this.jumpToHash);
    },
    openToc: function openToc(e) {
      e.preventDefault();
      $('.m-toc').toggleClass($.main.is_open_class);
    },
    jumpToHash: function jumpToHash(e) {
      var offset = 300;
      var target = $(this).attr('href');
      var position = $(target).position();
      var paragraph_country = $('.m-paragraph--country');

      if (paragraph_country.length > 0) {
        var header = document.querySelector('#header');

        if (header) {
          var headerRect = header.getBoundingClientRect();

          if (headerRect) {
            offset = headerRect.height * -1 - 60;
          }
        }
      }

      $('html, body').stop().animate({
        scrollTop: position.top + offset
      }, 500, 'swing');
    }
  };
  $(document).bind({
    pginit: function pginit(e, status) {
      $.mtoc.init();
    }
  });
})(jQuery);
"use strict";

window.newsletter = {
  paragraph_selector: '.m-paragraph--newsletter',
  paragraph_inner_selector: '.m-paragraph--newsletter__inner',
  header_selector: '#header',
  toolbar_selector: '#admin-menu',
  init: function init() {
    var inner = document.querySelector(window.newsletter.paragraph_inner_selector);

    if (inner) {
      inner.removeAttribute('style');
    }

    window.newsletter.scroll();
  },
  scroll: function scroll(e) {
    var newsletter = document.querySelector(window.newsletter.paragraph_selector);

    if (newsletter) {
      var newsletterRect = newsletter.getBoundingClientRect();
      var inner = newsletter.querySelector(window.newsletter.paragraph_inner_selector);
      var innerRect = inner.getBoundingClientRect();
      var headerRect;
      var header = document.querySelector(window.newsletter.header_selector);
      if (header) headerRect = header.getBoundingClientRect();
      var toolbarRect;
      var toolbar = document.querySelector(window.newsletter.toolbar_selector);
      if (toolbar) toolbarRect = toolbar.getBoundingClientRect();
      var innertop = innerRect.top; // console.log(headerRect.height)

      if (headerRect) {
        innertop = innertop - headerRect.height;
      }

      if (toolbarRect) {
        innertop = innertop - toolbarRect.height;
      }

      if (document.body.getBoundingClientRect().top > scrollPos) {
        // scroll down
        if (innertop >= 0) {
          if (newsletter.classList.contains('is-top')) {
            newsletter.classList.remove('is-top');
            inner.removeAttribute('style');
          }
        }
      } else {
        // scroll up
        if (innertop < 0) {
          if (!newsletter.classList.contains('is-top')) {
            newsletter.classList.add('is-top'); // if div has "background-attachment: fixed;" then positions are always based on body (not parent div) !!

            var backgroundPosTop = Math.ceil(headerRect.height) - 2;
            var backgroundPosRight = Math.ceil(newsletterRect.right - innerRect.right) - 29;

            if (toolbarRect) {
              backgroundPosTop = backgroundPosTop + Math.ceil(toolbarRect.height);
            }

            inner.style.backgroundPosition = 'top ' + backgroundPosTop + 'px right ' + backgroundPosRight + 'px';
          }
        }
      }

      scrollPos = document.body.getBoundingClientRect().top;
    }
  }
};
document.addEventListener('DOMContentLoaded', function () {
  window.newsletter.init();
});
var windowWidth = window.innerWidth;
window.addEventListener('resize', function () {
  if (window.innerWidth !== windowWidth) {
    window.newsletter.init();
  }
});
var scrollPos = 0;
window.addEventListener('scroll', function (event) {
  window.newsletter.scroll();
}, false);
"use strict";

window.newsroom = {
  init: function init() {
    function doOnDocumentLoaded() {
      window.newsroom.load();
    }

    if (document.readyState === 'loading') {
      document.addEventListener('DOMContentLoaded', doOnDocumentLoaded);
    } else {
      doOnDocumentLoaded();
    }
  },
  load: function load() {
    var elements = document.querySelectorAll('.m-news-frontpage__list');

    if (elements) {
      var _iteratorNormalCompletion = true;
      var _didIteratorError = false;
      var _iteratorError = undefined;

      try {
        for (var _iterator = elements[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
          var element = _step.value;
          window.newsroom.slide(element);
        }
      } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion && _iterator.return != null) {
            _iterator.return();
          }
        } finally {
          if (_didIteratorError) {
            throw _iteratorError;
          }
        }
      }
    }
  },
  slide: function slide(elements) {
    window.newsroom.slider(elements);
  },
  slider: function slider(element) {
    var slider = tns({
      "container": element,
      "speed": 800,
      "autoplayTimeout": 4000,
      "autoplay": false,
      "mouseDrag": true,
      "lazyload": false,
      "swipeAngle": false,
      "nav": false,
      "controls": true,
      "preventScrollOnTouch": false,
      "autoplayButtonOutput": false,
      "loop": false,
      "useLocalStorage": false,
      "responsive": {
        320: {
          items: 1.2,
          slideBy: 1,
          gutter: 0
        },
        768: {
          items: 1.8,
          slideBy: 1,
          gutter: 0
        },
        1400: {
          items: 2.3,
          slideBy: 2,
          gutter: 0
        },
        1600: {
          items: 2.8,
          slideBy: 2,
          gutter: 0
        }
      }
    });
  }
};
document.addEventListener('DOMContentLoaded', function () {
  window.newsroom.init();
});
// (function($) {
//   $.pager = {
//     selector: '.m-news_frontpage__view__list > ul',
//     item_selector: '.m-news-frontpage--view--listtrigger',
//     load_more_selector: '.m-loadmore',
//     pager_next_selector: '.pager-current + .pager-item',
//     pager_first_selector: '.pager-current.first',
//     pager_prev_selector: '.pager-current',
//     pager_selector: '.m-pager',
//     next_selector: '.m-loadmore__next',
//     prev_selector: '.m-loadmore__prev',
//     init: function() {
//       $.pager.start_pager($('.m-news_frontpage__view'));
//     },
//     start_pager: function(obj) {
//       if ($($.pager.selector, obj).length > 0) {
//         //update next/prev link
//         $.pager.update_pager(obj);
//         //data-direction
//         $($.pager.load_more_selector, obj).click(function(e) {
//           var link = $(e.target).parent();
//           if (link.hasClass($.main.is_active_class) === false) {
//             return false;
//           }
//           var direction = link.attr('data-direction');
//           //find next page
//           var url = $.pager.find(obj, direction);
//           //add loader
//           $.pgajax.addloader(obj, 0);
//           $(document).trigger('ajaxcall', [{
//             data: {url: url},
//             sucess: function(data, options) {
//               if ($(data).find(obj.selector).length > 0) {
//                 var list = $($.pager.item_selector, data);
//                 var pager = $($.pager.pager_selector, data);
//                 //append list
//                 $($.pager.selector, obj).html(list);
//                 //replace pager
//                 $($.pager.pager_selector, obj).html(pager.html());
//                 //update next/prev link
//                 $.pager.update_pager(obj);
//               }
//               //remove loader
//               $.pgajax.remove_loader(obj);
//             },
//             error: function(error_message) {
//             }
//           }]);
//           return false;
//         });
//       }
//     },
//     find: function(obj, direction) {
//       if (direction === 'next') {
//         var url = $($.pager.pager_next_selector + ' a', obj).attr('href');
//       }
//       else {
//         var url = $($.pager.pager_prev_selector, obj).prev().find('a').attr('href');
//       }
//       return url;
//     },
//     update_pager: function(obj) {
//       if ($($.pager.pager_next_selector, obj).length > 0) {
//         $($.pager.next_selector, obj).addClass($.main.is_active_class);
//       }
//       else {
//         $($.pager.next_selector, obj).removeClass($.main.is_active_class);
//       }
//       if ($($.pager.pager_first_selector, obj).length > 0) {
//         $($.pager.prev_selector, obj).removeClass($.main.is_active_class);
//       }
//       else {
//         $($.pager.prev_selector, obj).addClass($.main.is_active_class);
//       }
//     }
//   }
//   $(document).bind({
//     pginit: function(e, status) {
//       $.pager.init();
//     }
//   })
// }(jQuery))
"use strict";
"use strict";

(function ($) {
  $.searchmobile = {
    init: function init() {
      if ($.browser.is_mobile === false) {
        return;
      }

      $.searchmobile.add_load_more($('.m-searchresultsblock--dossier__results'));
      $.searchmobile.add_load_more($('.m-searchresultsblock--article__results'));
      $.searchmobile.add_load_more($('.m-searchresultsblock--factsheet__results'));
      $.searchmobile.add_load_more($('.m-searchresultsblock--expert__results'));
      $.searchmobile.add_load_more($('.m-searchresultsblock--event__results'));
      $.searchmobile.add_load_more($('.m-searchresultsblock--others__results'));
      $.searchmobile.add_load_more($('.m-searchresultsblock--interest_point__results'));
    },
    add_load_more: function add_load_more(obj) {
      if (obj.length === 0) {
        return;
      }

      if ($('>li', obj).length < 6) {
        return;
      }

      var loadmore = $(document.createElement('a'));
      loadmore.html('show all');
      loadmore.addClass('m-searchresultsblock__loadmore');
      obj.append(loadmore);
      $('.m-searchresultsblock__loadmore', obj).click(function () {
        $('>li', obj).addClass($.main.is_active_class);
        $(this).hide();
        return false;
      });
    }
  };
  $(document).bind({
    pginit: function pginit(e, status) {
      $.searchmobile.init();
    }
  });
})(jQuery);
"use strict";

(function ($) {
  $.sideToolbar = {
    animate_in_progress: false,
    idleTimer: null,
    init: function init() {
      var $toolbar = $('.m-side_toolbar');
      var toolbarWidth = $toolbar.width();
      $toolbar.hover(function () {
        clearTimeout($.sideToolbar.idleTimer);
        $.sideToolbar.open_sidebar($toolbar, toolbarWidth);
      }, function () {
        $.sideToolbar.close_sidebar($toolbar, toolbarWidth);
      });

      if ($.browser.is_mobile === false && $.browser.is_tablet === false) {
        $.sideToolbar.open_sidebar($toolbar, toolbarWidth);
        $.sideToolbar.idleTimer = setTimeout(function () {
          $.sideToolbar.close_sidebar($toolbar, toolbarWidth);
        }, 2000);
      } // $('.m-side_toolbar__newsletter').on('click', function(e) {
      //   //e.preventDefault();
      //   var obj = $(this);
      //   var url = '/ajax/block/newsletter';
      //   obj.colorbox({
      //     'href': url,
      //     onComplete: function() {
      //       var $cboxTitle = $('#cboxTitle');
      //       var $cboxCurrent = $('#cboxCurrent');
      //       if (!$cboxTitle.text()) {
      //         $cboxTitle.remove();
      //       }
      //       if (!$cboxCurrent.text()) {
      //         $cboxCurrent.remove();
      //       }
      //       $('#colorbox .webform-component-newsletter_email .form-checkboxes label').on('click', function(e) {
      //         e.preventDefault();
      //         var checkbox = $(this).parents('.form-type-checkbox').find('.form-checkbox');
      //         checkbox.trigger('click');
      //       });
      //     }
      //   });
      //   $(document).bind('cbox_complete', function() {
      //     $.sideToolbar.submit();
      //   });
      // });
      // $('.m-servicemenu__container [href="#newsletter"]').click(function() {
      //   $('.m-side_toolbar__newsletter').trigger('click');
      //   return false;
      // });

    },
    open_sidebar: function open_sidebar($toolbar, toolbarWidth) {
      if ($.sideToolbar.animate_in_progress === false) {
        $.sideToolbar.animate_in_progress = true;
        anime({
          targets: '.m-side_toolbar',
          translateX: (toolbarWidth - 67) * -1,
          easing: 'easeInOutQuart',
          duration: 200,
          complete: function complete() {
            $.sideToolbar.animate_in_progress = false;
          }
        });
      }
    },
    close_sidebar: function close_sidebar($toolbar, toolbarWidth) {
      if ($.sideToolbar.animate_in_progress === false) {
        $.sideToolbar.animate_in_progress = true;
        anime({
          targets: '.m-side_toolbar',
          easing: 'easeInOutQuart',
          translateX: 0,
          duration: 300,
          complete: function complete() {
            $.sideToolbar.animate_in_progress = false;
          }
        });
      }
    } // submit: function() {
    //   $('#cboxLoadedContent input[type="submit"], .m-node--frontpage__newsletter input[type="submit"]').on('click', function() {
    //     //validate email
    //     //var form = $('#cboxLoadedContent form');
    //     var form = $(this).closest('form');
    //     var url = form.attr('action');
    //     var formData = form.serialize();
    //     //send form via ajax
    //     $(document).trigger('ajaxcall', [{
    //       data: {url: url, data: formData},
    //       sucess: function(data, options) {
    //         var container = $(document.createElement('div'));
    //         container.append(data);
    //         var response = $(document.createElement('div'));
    //         response.addClass('webform-confirmation');
    //         response.html($('.messages.status, .webform-confirmation', container));
    //         $('#cboxLoadedContent').html(response);
    //         $(document).colorbox.resize();
    //       },
    //       error: function(error_message) {
    //       }
    //     }]);
    //     return false;
    //   });

  };
  $(document).bind({
    pginit: function pginit(e, status) {
      $.sideToolbar.init();
    }
  });
})(jQuery);
"use strict";

(function ($) {
  $.sidebarTabs = {
    init: function init() {
      $(document).on('click', '.m-sidebars--tabs a', function (e) {
        e.preventDefault();
        var section = $(this).parent().data('section');
        $('.m-sidebars--further-reading .active').removeClass('active');
        $(this).parent().addClass('active');
        $(".m-sidebars--tabs-content[data-section=".concat(section, "]")).addClass('active');
      });
    }
  };
  $(document).bind({
    pginit: function pginit(e, status) {
      $.sidebarTabs.init();
    }
  });
})(jQuery);
"use strict";

(function ($) {
  $.subtopic = {
    init: function init() {
      $('.m-subtopic__mobileselector').click(this.onSelectorClick);
    },
    onSelectorClick: function onSelectorClick(e) {
      e.preventDefault();
      $('.m-subtopic').toggleClass($.main.is_open_class);
    }
  };
  $(document).bind({
    pginit: function pginit(e, status) {
      $.subtopic.init();
    }
  });
})(jQuery);
"use strict";

(function ($) {
  $.clew_survey = {
    init: function init() {
      if ($('.m-clew-survey-overlay').size() > 0) {
        if ($('form#user-login').size() === 0) {
          var cookie = $.cookie('cookie_clew_survey');

          if (!cookie) {
            $('.m-clew-survey-overlay__wrapper').show();
            $('.m-clew-survey-overlay__darken').show();
            $('.region-overlay').addClass('is-initial');
            $(document).on('click', '.m-clew-survey-overlay__content a', function (e) {
              e.preventDefault();
              $.cookie('cookie_clew_survey', '1', {
                expires: 2,
                path: '/'
              });
              var href = $(this).attr('href');

              if (href) {
                window.location.href = href;
              }
            });
            $(document).on('click', '.m-clew-survey-overlay__close', function (e) {
              $('.m-clew-survey-overlay__wrapper').hide();
              $('.m-clew-survey-overlay__darken').hide();
              $('.region-overlay').removeClass('is-initial');
              $.cookie('cookie_clew_survey', '1', {
                expires: 2,
                path: '/'
              });
              return false;
            });
          }
        }
      }
    }
  };
  $(document).bind({
    pginit: function pginit(e, status) {
      $.clew_survey.init();
    }
  });
})(jQuery);
"use strict";

(function ($) {
  $.tooltip_starter = {
    init: function init() {
      var selector = '.field-name-field-global-text a, .field-name-field-global-body a, a.lexicon-term';
      $(selector).tooltipster({
        content: 'Loading...',
        animation: 'fade',
        delay: 10,
        speed: 200,
        touchDevices: true,
        trigger: 'hover',
        maxWidth: 400,
        interactive: true,
        onlyOne: true,
        contentAsHTML: true,
        functionBefore: function functionBefore(origin, continueTooltip) {
          var content = $(origin).data('title');

          if (!content) {
            var link_host = $.tooltip_starter.getHost($(origin).attr('href'));
            var page_host = $.tooltip_starter.getHost(window.location.href);

            if (link_host === undefined || link_host === page_host) {
              return false;
            }

            content = link_host;
          }

          continueTooltip();
          return origin.tooltipster('content', "<p>" + content + "</p>");
        }
      });
    },
    getHost: function getHost(href) {
      var pathArray = href.split('/');
      return pathArray[2]; // host
    }
  };
  $(document).bind({
    pginit: function pginit(e, status) {
      $.tooltip_starter.init();
    }
  });
})(jQuery);